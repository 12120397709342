// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // production: false,
  // baseUrl:'https://localhost:44395',
  // allowedDomains:'localhost:44395',
  // siteUrl:'http://localhost:4200',
  // domainName:'http://localhost:4200',

  production: true, 
  baseUrl:'https://api.catholic-catechism-eg.com',
  allowedDomains:'https://api.catholic-catechism-eg.com',
  siteUrl:'https://catholic-catechism-eg.com',
  domainName:'catholic-catechism-eg.com',
   

  AzureSorageContainerPath:'https://catholic.blob.core.windows.net/',
  newsAndArticalsContainer:'newsandarticals',
 mainContainer:'main',
 curriculaContainer:'curricula',
 educationaltoolsContainer:'educationaltools',
 lessonsContainer:'lessons',
 libraryContainer:'library',
 relatedsitesContainer:'relatedsites',
 spiritualeventsContainer:'spiritualevents'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
